/**
 * Box showing statistics about the program
 */

import React, { Component } from "react";

import PropTypes from "prop-types";

import clock from "./img/clock.svg";
import bulb from "./img/bulb.svg";
import money from "./img/money.svg";
import hammer from "./img/hammer.svg";
import location from "./img/location.svg";

import "./index.scss";

class StatsBox extends Component {
  render() {
    // To avoid having to change this every year, we auto change the year
    // and edition count starting each November
    const currentMonth = new Date().getMonth() + 1;
    const isShowingNextYear = currentMonth > 10 ? 1 : 0;
    const year = new Date().getFullYear() + isShowingNextYear;
    const edition = year - 2012;

    return (
      <div className="StatsBox">
        <div className="stats">
          <div className="stat">
            <img src={clock} alt="" />
            <h2>
              {edition}th edition <span> in {year}! </span>
            </h2>
          </div>
          <div className="stat">
            <img src={bulb} alt="" />
            <h2>
              almost 700<span> supported teams </span>
            </h2>
          </div>
          <div className="stat">
            <img src={money} alt="" />
            <h2>
              500,000€<span>investment prize</span>
            </h2>
          </div>
          <div className="stat">
            <img src={hammer} alt="" />
            <h2>
              over 200M€{" "}
              <span style={{ wordWrap: "break-word" }}> total alumni </span>
              <span style={{ bottom: "-30px" }}> startups valuation </span>
            </h2>
          </div>
          <div className="stat" style={{ marginTop: "25px" }}>
            <img src={location} alt="" />
            <h2 style={{ fontSize: "20px" }}>
              {" "}
              teams from <br /> 22 universities <span> in 11 cities </span>{" "}
            </h2>
          </div>
        </div>
        <div className="text">
          <div className="container">
            <h1>
              {" "}
              Are you a young visionary that aims to build a tech start-up?{" "}
            </h1>
            <p>
              {" "}
              Join us to turn your tech vision into a product through fine-tuned
              mentorship, access to bleeding-edge technologies and interaction
              with top companies, mentors and angel investors.{" "}
            </p>
          </div>
          <div className="container">
            <h1>
              {" "}
              Are you an innovative company looking to connect with the most
              vibrant innovation eco-systems?{" "}
            </h1>
            <p>
              {" "}
              Join the main university based tech start-up accelerator program
              in Romania - and be part of a network that fosters innovation,
              collaboration and digital transformation.{" "}
            </p>
          </div>
          {/* <div>
            <h1> {title} </h1>
            <h1> {subtitle} </h1>
          </div>
          <p>{description}</p> */}
        </div>
      </div>
    );
  }
}

StatsBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

StatsBox.defaultProps = {
  title: "Take an eye-opening journey",
  subtitle: "from idea to product",
  description:
    "Join us to turn your tech vision into a product though fine-tuned mentorship, access to bleeding-edge technologies and interaction with top companies and angel investors. Each year, up to 60 teams work from March to May alongside mentors, to improve their design, captivate users, develop & test their prototype into a MVP. In May, Demo Day brings the best teams into spotlight to gain outreach, traction and continued support from our network of alumni and business connections.",
};

export default StatsBox;
