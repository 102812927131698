/**
 * Tech page component
 */

import React, { Component } from "react";

import Header from "components/Common/Header";
import CitySelector from "components/Common/CitySelector";
import Partner from "components/Common/Partner";

// import Markdown from 'react-markdown'

// import Fab from './assets/fab.png'
// import G5 from './assets/5G_orange.png'
// import IOT from './assets/IoT_Connectivity_orange.png'
// import SmartAir from './assets/Smart_Air_Quality_Monitor_orange.png'
// import IOT from './assets/Machine-to-Machine.png'
// import LiveObjects from './assets/Device-Managment-Express.png'
// import LTEM from './assets/Antenna.png'
// import CloudConnect from './assets/Cloud-connect.png'
// import wifiapi from './assets/wifiapi.png'
// import Security from './assets/xdlab.png'
import HeaderImage from "./assets/communities.jpg";
import Partners from "../../components/Common/Partners";
import axios from "axios";
// import locate from 'common/location'
// import locate from '../../common/location'
import "./index.scss";

class Communities extends Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    partners: [],
    city: "",
  };

  componentDidMount() {
    axios
      .get("/api/partners/")
      .then((x) => this.setState({ ...this.state, partners: x.data }));

    this.setState({ city: "Bucharest" });
    // locate((city) => this.setState({ ...this.state, city }))
  }
  // componentWillMount() {
  //   axios
  //     .get('/api/partners/')
  //     .then(x => this.setState({ ...this.state, partners: x.data }))
  // }

  onClick = (selected) => this.setState({ ...this.state, selected });

  get partner() {
    const { partners, selected } = this.state;

    const partner = partners.filter((x) => x.name.toLowerCase() === selected);

    if (partner.length === 0) {
      return null;
    }

    return <Partner {...partner[0]} />;
  }

  render() {
    const { selected, partners, city } = this.state;
    const { onClick, options } = this;

    const selectorProps = {
      selected,
      options,
      onClick,
    };

    return (
      <div>
        <Header title="Communities" image={HeaderImage} />
        <section className="Tech">
          <Partners data={partners} city={city} />
          {/* {partner} */}
          {/* <Markdown escapeHtml={false} source={content} /> */}
        </section>
      </div>
    );
  }
}

export default Communities;
