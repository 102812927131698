/**
 * Generic city selector component
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import "./index.scss";

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.string,
  onClick: PropTypes.func,
  sortOptions: PropTypes.bool,
};
const defaultProps = {
  options: [],
  selected: "",
  onClick: (_) => {},
  sortOptions: true,
};

function unique(list) {
  return list.filter((x, i) => list.indexOf(x) === i);
}

class CitySelector extends Component {
  getOptionClass = (val) => (val === this.props.selected ? "active" : "");

  get options() {
    let onClick = (e) => this.props.onClick(e.target.textContent.trim());
    let opts = unique(this.props.options);

    return opts.map((x, i) => (
      <li key={i} className={this.getOptionClass(x)} onClick={onClick}>
        {x}
      </li>
    ));
  }

  render() {
    return <ul className="CitySelector">{this.options}</ul>;
  }
}

CitySelector.propTypes = propTypes;
CitySelector.defaultProps = defaultProps;

export default CitySelector;
