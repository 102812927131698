import React from "react";
import "./index.scss";

export function SupportBox() {
  return (
    <>
      <div className="SupportBox-container PreRegisterButton Vertical WithBg">
        <h1>Register now for:</h1>
        <div className="buttons">
          
          <a
            className="SupportBox-container PreRegisterButton"
            href={"https://il.calemis.org/#inscriu"}
            target="_blank"
          >
            <button>The Iasi Hackathon (22 & 23 March)</button>
          </a>
          <a
            className="SupportBox-container PreRegisterButton"
            href={"https://ye35yotivr1.typeform.com/ILabsCluj2025 "}
            target="_blank"
          >
            <button>The Cluj-Napoca Hackathon (22 & 23 March)</button>
          </a>
          <a
            className="SupportBox-container PreRegisterButton"
            href={"https://ye35yotivr1.typeform.com/to/oqj7vdFq "}
            target="_blank"
          >
            <button>The Timisoara Hackathon (22 & 23 March)</button>
          </a>
        </div>
      </div>
      <div className="SupportBox-container darker">
        <h1>Your support counts for innovation!</h1>
        <div>
          <a
            target="_blank"
            href="https://redirectioneaza.ro/asociatia-tech-lounge"
          >
            <button>3.5% Tax Contribution</button>
          </a>
          <a target="_blank" href="https://buy.stripe.com/8wMeX31XG0ifgh2001">
            <button>Make a donation</button>
          </a>
        </div>
      </div>
    </>
  );
}
