/**
 * WhoWeAre listing routing component
 */

import React, { Component, Fragment } from "react";

import axios from "axios";
import locate from "common/location";

import Header from "components/Common/Header";
import Listing from "components/WhoWeAre";

import image from "./img/header.jpg";

class WhoWeAre extends Component {
  state = {
    data: [],
    city: "Core",
    error: false,
  };

  componentWillMount = async () => {
    let res;

    window.scrollTo(0, 0);

    try {
      res = await axios.get("/api/whoweare/");
    } catch (e) {
      this.setState({ error: true });
    }

    this.setState({ data: res.data });

    // locate((city) => this.setState({ city }));
  };

  render() {
    const { data, error, city } = this.state;
    const { params } = this.props.match;
    let location;

    if (params.hasOwnProperty("category")) {
      location = params.category;
    } else {
      location = city;
    }

    const content = error ? (
      <h2> An error has occured loading data. Try again a bit later </h2>
    ) : (
      <Listing data={data} city={location} />
    );

    return (
      <Fragment>
        <Header title="Who we are" image={image} />
        {content}
      </Fragment>
    );
  }
}

export default WhoWeAre;
