/**
 * Home routing component
 */

import React, { Component } from "react";

import Header from "components/Common/Header";
import StatsBox from "components/Common/StatsBox";
// import Logos from 'components/Common/Logos'

// import AsSeenOnBar from 'components/Home/AsSeenOnBar'
import RegisterNow from "components/Home/RegisterNow";

// import Schedule from 'components/Home/Schedule'
import OpenChallenges from "components/Home/OpenChallenges";

import SmallTimeline from "components/Home/SmallTimeline";
// import TeamsShowcase from 'components/Home/TeamsShowcase'
import MentorsList from "components/Home/MentorsList";

import { FacebookProvider, EmbeddedPost } from "react-facebook";

import axios from "axios";
import TeamsShowcase2 from "../../components/Home/TeamsShowcase2";
import { SupportBox } from "components/Home/SupportBox";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bestTeams: [],
      homepageMentors: [],
      partners: [],
      schedule: [],
      city: "",
    };
  }

  componentWillMount() {
    // axios
    //   .get('/api/homepageTeams/')
    //   .then(x => this.setState({ ...this.state, bestTeams: x.data }))

    axios
      .get("/api/homepageMentors/")
      .then((x) => this.setState({ ...this.state, homepageMentors: x.data }));

    axios
      .get("/api/schedule/")
      .then((x) => this.setState({ ...this.state, schedule: x.data }));
  }

  render() {
    // const { city, schedule, homepageMentors } = this.state
    const { homepageMentors } = this.state;

    return (
      <div>
        <Header />
        <SupportBox />
        {/* <AsSeenOnBar /> */}
        <RegisterNow />

        <StatsBox />
        <OpenChallenges />

        <SmallTimeline />
        {/* <Schedule city={city} data={schedule} /> */}

        <TeamsShowcase2 />
        {/* <Logos /> */}

        {/* <TeamsShowcase data={bestTeams} /> */}
        <MentorsList data={homepageMentors} />
        <div className="facebookIntegration">
          <h1>Our social media updates</h1>
          <div className="list">
            <FacebookProvider appId="1543118335969332">
              <EmbeddedPost
                href="https://www.facebook.com/ILabsRo/videos/429416813224914"
                width="500"
                height="450"
              />
            </FacebookProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
